
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import PageContent from 'Components/layout/panel/PageContent';
import ActivityLogsList from 'Components/admin/activityLogs/List';

export const TAB_LIST = 'list';

export default class AdminActivityLogs extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-activity-logs"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Logi aktywności"
                        tabs={[{
                            key: ActivityLogsList,
                            label: 'Lista',
                            children: (
                                <ActivityLogsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
